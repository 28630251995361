import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'
import HomeIcon from '@mui/icons-material/Home'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { Homepage } from '../utils/navLinks'
import Link from '../components/link'

import Fireworks from '../assets/svg/fireworks.svg'

function NotFoundPage() {
    const { t } = useTranslation('thankYou')

    return (
        <Layout>
            <SEO title="thankYou:title" description="thankYou:message" />
            <div className="thankYouPage">
                <Fireworks className="thankYouImage" />
                <div>
                    <h1 className="pageTitle">{t('header')}</h1>
                    <p>{t('message')}</p>
                    <Button
                        to={Homepage.path}
                        component={Link}
                        variant="contained"
                        disableElevation
                        className="homeButton"
                        startIcon={<HomeIcon />}
                    >
                        {t('link')}
                    </Button>
                </div>
            </div>
        </Layout>
    )
}

export default NotFoundPage
